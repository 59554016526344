import {useLoadCurrentPlan, useView} from '@brikit/tabjay-ui-kit'
import {useQueryCurrentPlan} from 'hooks/queries/use_query_current_plan'
import useQueryGoogleThumbnails from 'hooks/queries/use_query_google_thumbnails'
import {initializeUiKitServerFunctions} from 'lib/ui_kit_server_functions'
import {useRouter} from 'next/router'
// import {isExtension} from 'utils/helper_functions'

const BrowsePage = () => {
  const {loading, loggedInUserIds} = useLoadCurrentPlan(useQueryCurrentPlan, useQueryGoogleThumbnails)

  initializeUiKitServerFunctions()

  const router = useRouter()

  const view = useView({loading, loggedInUserIds})

  console.log('view', view)

  return router.isReady ? view : null
}

// async function serverSideProps(context) {
//   const noData = {props: {metadata: null}}
//   if (!context.req || context.req.url === '&') return noData

//   // No task id in url query
//   if (!context.query.id) return noData

//   // User navigated here from the website instead of hard reload
//   if (context.resolvedUrl !== context.req.url) return noData

//   const res = await fetch(`${process.env.NEXT_PUBLIC_NODE_API}/task_metadata/${context.query.id}`)
//   const metadata = await res.json()

//   return {
//     props: {metadata: metadata}
//   }
// }

export default BrowsePage
// export const getServerSideProps = !isExtension ? serverSideProps : undefined