import {Models, Plan} from '@brikit/tabjay-ui-kit'
import {useQuery} from 'react-query'
import {tabjayNodeApi} from 'utils/tabjay_api_utils'
import {URL} from 'utils/urls'
import {useQueryUser} from './use_query_user'

const currentPlanQueryFunction = async (loggedInUserIds) => {
  const currentPlanId = Plan.getCurrentPlanId(loggedInUserIds)
  if (!currentPlanId) {return console.log('missing currentPlanId')}

  const res = await tabjayNodeApi.get(`${URL.route.current_plan}?id=${currentPlanId}`)
  const plan = res.data

  Models.reloadPlan(plan)

  return plan
}

const useQueryCurrentPlan = () => {
  const {userData, userStatus, userError, loggedInUserIds} = useQueryUser()

  const {status, data, error} = useQuery({
    queryKey: [URL.route.current_plan],
    queryFn: () => currentPlanQueryFunction(loggedInUserIds),
    enabled: !!userData
  })

  const errorMessage = userError || error

  const loading = (userStatus !== 'success' && userStatus !== 'error') || (status !== 'success' && status !== 'error')

  return {loading, errorMessage, planData: data, userData, loggedInUserIds}
}

export {currentPlanQueryFunction, useQueryCurrentPlan}

