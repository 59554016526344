import {isBrowserEnv} from '@brikit/tabjay-ui-kit'

const TabjayExtension = {
  getTabs: function () {
    if (!isBrowserEnv) return null
    const domain = process.env.NODE_ENV === 'development' ? 'localhost' : 'tabjay.com'
    const allTabs = JSON.parse(localStorage.getItem('userChromeTabs')) || []

    // Note the tab.active is always one behind where it should be.
    const tabs = allTabs?.filter(tab => tab.url !== 'chrome://newtab/')
      .filter(tab => (!tab.url.includes(domain)))

    return tabs
  },

  getHistory: function () {
    if (!isBrowserEnv) return null

    const history = JSON.parse(localStorage.getItem('userChromeHistory')) || null
    return history
  },

  tabUrls: function () {
    return this.getTabs()?.map(tabs => tabs.url)
  },

  historyUrls: function () {
    return this.getHistory()?.map(history => history.url).slice(0, 25)
  },

  allUrls: function () {
    return [...this.tabUrls(), ...this.historyUrls()]
  },

  browserData: function () {
    return {tabs: this.getTabs(), history: this.getHistory()}
  },
}

export default TabjayExtension

/*
  Chrome tab payload:
  {
    "active": false,
    "audible": false,
    "autoDiscardable": true,
    "discarded": false,
    "favIconUrl": "https://calendar.google.com/googlecalendar/images/favicons_2020q4/calendar_26.ico",
    "groupId": -1,
    "height": 971,
    "highlighted": false,
    "id": 1360793772,
    "incognito": false,
    "index": 0,
    "mutedInfo": {
      "muted": false
    },
    "pinned": false,
    "selected": false,
    "status": "complete",
    "title": "Brikit - Calendar - Week of October 23, 2022",
    "url": "https://calendar.google.com/calendar/u/0/r",
    "width": 1680,
    "windowId": 1360793771
  }

  Chrome history payload:
  {
    "id": "5250",
    "lastVisitTime": 1666808730361.306,
    "title": "(4) r/popular",
    "typedCount": 0,
    "url": "https://www.reddit.com/r/popular/",
    "visitCount": 42
  }
*/
