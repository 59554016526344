import {Models} from '@brikit/tabjay-ui-kit'
import {useQuery} from 'react-query'
import {authorizePrompts} from 'utils/authorization_prompts'
import {tabjayNodeApi} from 'utils/tabjay_api_utils'
import {URL} from 'utils/urls'

const useQueryUserFunction = async () => {
  const res = await tabjayNodeApi.get(URL.route.current_users)
  const users = res.data

  Models.reloadUsers(users, authorizePrompts)
  // setCookie('browser-extension-id', user.browser_extension_id)

  return users
}

const useQueryUser = () => {
  // DISCUSS WITH DARRYL:
  // https://www.npmjs.com/package/cookies-next#httponly
  // const loggedInUserIds = useLoggedInUserIds()

  const {status, data, error} = useQuery({
    queryKey: [URL.route.current_users],
    queryFn: useQueryUserFunction,
    retry: false,
  })

  const loggedInUserIds = data?.map(user => user.id)
  console.log('loggedInUserIds', loggedInUserIds)

  return {userStatus: status, userData: data, userError: error, loggedInUserIds}
}

export {useQueryUser, useQueryUserFunction}
