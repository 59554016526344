import {Link, TokenManager} from "@brikit/tabjay-ui-kit"
import _ from "lodash"
import {useQuery} from "react-query"
import GoogleApi from "services/google_api"

const thumbnailQueryFunction = async () => {
  const googleLinks = Link.googleFiles()
  const validTokens = TokenManager.googleTokens()?.filter(t => TokenManager.isTokenValid(t))
  if (!googleLinks || _.isEmpty(validTokens)) {return }

  const files = await GoogleApi.getFiles(googleLinks)

  // Update matching links with latest thumbnailUrl 
  files.forEach(f => {
    const linkModel = Link.findById(f.id)
    linkModel.image = f.thumbnailLink
  })

  return files
}

export default function useQueryGoogleThumbnails(user, plan) {
  const {thumbnailStatus, data, thumbnailError} = useQuery({
    queryKey: '/google-thumbnails',
    queryFn: thumbnailQueryFunction,
    retry: false,
    enabled: !!user && !!plan
  })

  const thumbnails = data

  return {thumbnailStatus, thumbnails, thumbnailError}
}

