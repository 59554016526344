import {BrowserData, Mutations, ROUTES, Server} from '@brikit/tabjay-ui-kit'
import axios from 'axios'
import {router} from 'next/router'
import LinkMetadata from 'services/link_metadata'
import TabjayExtension from 'services/tabjay_extension'
import {isExtension} from 'utils/helper_functions'
import {tabjayNodeApi} from 'utils/tabjay_api_utils'
import {URL} from 'utils/urls'

export const signinFunctions = {
  email: async ({setRoute, email}) => {
    const res = await axios.post(URL.login.magic_link, {email})
    if (res.status === 200) {setRoute(ROUTES.MAGIC_LINK_SENT)}
  },
  google: () => {
    isExtension
      ? window.open(`${process.env.NEXT_PUBLIC_CLIENT_URL}${URL.login.google}`, '_blank') && window.close()
      : router.push(URL.login.google)
  },
  apple: () => {
    isExtension
      ? window.open(`${process.env.NEXT_PUBLIC_CLIENT_URL}${URL.login.apple}`, '_blank') && window.close()
      : router.push(URL.login.apple)
  }
}

export const trialFunctions = {
  buy: async data => {
    return await tabjayNodeApi.post(URL.route.stripe.portal, data).then(res => router.replace(res.data.url))
  },

  extendTrial: async data => {
    if (data.trialCount == 1) return Mutations.mutations.createTrialQuestions.mutate(data)
    if (data.trialCount == 2) return Mutations.mutations.editTrialQuestions.mutate(data)
  },

  deactivatePlan: async data => {
    return await Mutations.mutations.editTrialQuestions.mutate({
      planId: data.planId,
      expectations: data.expectations,
      shortfall: data.shortfall,
      trialCount: data.trialCount
    })
  }
}

export const initializeUiKitServerFunctions = () => {
  Server.getUrlMetadata = LinkMetadata.getUrl
  Server.signinFunctions = signinFunctions
  Server.trialFunctions = trialFunctions
  BrowserData.browser = TabjayExtension.browserData()
}