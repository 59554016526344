import {TokenManager} from '@brikit/tabjay-ui-kit'
import _ from 'lodash'
import {getIdFromGoogleUrl} from 'utils/helper_functions'
import {tabjayNodeApi} from 'utils/tabjay_api_utils'
import {URL} from 'utils/urls'
import GoogleApi from './google_api'

const LinkMetadata = {

  options: function () {
    return {
      headers: {'X-Auth': `Basic ${TokenManager.getSignedTokens()}`}
    }
  },

  getUrl: async function (url) {
    if (URL.isGoogleFile(url)) {
      const externalId = getIdFromGoogleUrl(url)
      const response = await GoogleApi.getFile(externalId, true)
      if (response?.data) {return response}
    }

    if (URL.isConfluenceUrl(url)) {
      // Call api to get confluence page
    }

    return tabjayNodeApi.post(URL.route.scrape_url, {urls: [url]}, LinkMetadata.options())
  },

  getUrls: async function (urls) {
    const batches = _.chunk(urls, 25)
    const results = []

    for (const batch of batches) {
      const metadata = await tabjayNodeApi.post(URL.route.scrape_url, {urls: urls}, LinkMetadata.options())
      results.push(metadata.data)
    }

    return _.flatten(results)
  },

  mergeMetaBrowserData: function (metadata, browserData) {
    const mergedData = metadata.map(md => {
      let browserItem = browserData.find(b => b.url === md.url)

      return {
        id: md.url,
        name: browserItem?.name || md.name || md.url,
        url: md.url,
        icon: md.icon || browserItem?.icon,
        image: md.image,
        description: md.description,
        lastVisited: browserItem?.last_visited,
        active: browserItem?.active,
        selected: browserItem?.selected,
        pinned: browserItem?.pinned,
        visitCount: browserItem?.visit_count
      }
    })

    return mergedData
  }
}

export default LinkMetadata
